import Vue from "vue";
import VueRouter from "vue-router";
import UserType from "@/constants/user_type";

import Home from "@/views/Home.vue";
import Login from "@/views/Login/Login.vue";
import Roles from "@/views/Roles/Roles.vue";

import Users from "@/views/Users/Users.vue";
import Clients from "@/views/Clients/Clients.vue";
import DeliveryPoint from "@/views/General/PuntoDeEntrega/PuntoDeEntrega.vue";
import BalancesClient from "@/views/Clients/Balances.vue";

import PriceList from "@/views/General/PriceList/PriceList.vue";
import PriceListDetail from "@/views/General/PriceList/PriceListDetail.vue";
import Products from "@/views/General/Products/Products.vue";
import ProductType from "@/views/General/Products/ProductType/ProductType.vue";
import Categories from "@/views/General/Categories/Categories.vue";
import SubCategories from "@/views/General/SubCategories/SubCategories.vue";
import PaymentMethods from "@/views/General/PaymentMethods/PaymentMethods.vue";

import Suppliers from "@/views/General/Suppliers/Suppliers.vue";
import BalancesSupplier from "@/views/General/Suppliers/Balances.vue";

import MoneyAccounts from "@/views/General/MoneyAccounts/MoneyAccounts.vue";
import BankMovements from "@/views/General/BankMovements/BankMovements.vue";

import Spents from "@/views/Spents/Spents.vue";
import Payments from "@/views/Spents/Payments.vue";

import Charges from "@/views/Charges/Charges.vue";
import Remitos from "@/views/Remitos/Remitos.vue";

import Metrics from "@/views/Metrics/index.vue";
import Integrations from "@/views/Integrations/Mp.vue";
import Settings from "@/views/Settings/index.vue";

import Employees from "@/views/Employees/Employees.vue";
import Salesman from "@/views/General/Salesman/Salesman.vue";
import Cheques from "@/views/Cheques/Cheques.vue";

//STOCK
import Warehouses from "@/views/Stock/Warehouses/Warehouses.vue";
import Stocks from "@/views/Stock/Stocks/Stocks.vue";
import HistorialStocks from "@/views/Stock/HistorialStocks/HistorialStocks.vue";

//FACTURACION
import Emitters from "@/views/Billing/Emitters/Emitters.vue";
import Receivers from "@/views/Billing/Receivers/Receivers.vue";
import Vouchers from "@/views/Billing/Vouchers/Vouchers.vue";

import SaleOrder from "@/views/SaleOrder/SaleOrder.vue";
import PurchaseOrder from "@/views/PurchaseOrder/PurchaseOrder.vue";

//REPORTES
import ReporteCuentasPorCobrar from "@/views/Reports/CuentasPorCobrar.vue";
import ReporteCuentasPorPagar from "@/views/Reports/CuentasPorPagar.vue";
import ReporteVenta from "@/views/Reports/Ventas/ReporteVenta.vue";
import ComprobantesPendientesDeCobroPorCliente from "@/views/Reports/ComprobantesPendientesDeCobroPorCliente.vue";
import ComprobantesPendientesDePagoPorProveedor from "@/views/Reports/ComprobantesPendientesDePagoPorProveedor.vue";
import ReporteSalesmanCommission from "@/views/Reports/SalesmanCommission.vue";
import ReportRetentions from "@/views/Reports/Retenciones.vue";
import ReportPerceptions from "@/views/Reports/Percepciones.vue";
import CentrosDeCosto from "@/views/Reports/CentrosDeCosto.vue";

import CostsCenters from "@/views/General/CostsCenters/CostsCenters.vue";
import SubCostsCenters from "@/views/General/SubCostsCenters/SubCostsCenters.vue";

//LOGISTICA
import Carrier from "@/views/Logistic/Carrier/Carrier.vue";
import Zone from "@/views/Logistic/Zone/Zone.vue";
import Rate from "@/views/Logistic/Rate/Rate.vue";
import Shipping from "@/views/Logistic/Shipping/Shipping.vue";

import ComprobantesPendientesDeCobro from "@/views/Reports/ComprobantesPendientesDeCobro.vue";
import ComprobantesPendientesDePago from "@/views/Reports/ComprobantesPendientesDePago.vue";
import ClientType from "@/views/Clients/ClientType/ClientType.vue";
import ClientZone from "@/views/Clients/ClientZone/ClientZone.vue";

import Constant from "@/constants/sections";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
import { authService } from "@/libs/ws/authService";
import IvaLibroDigital from "../views/Reports/IvaLibroDigital.vue";

Vue.use(VueRouter);

/**
 * Routes general
 */
const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/",
    redirect: "/" + Constant.SECTION_METRICS,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_ROLES,
    name: "Roles",
    component: Roles,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_USERS,
    name: "Usuarios",
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_INTEGRATIONS,
    name: "Integraciones",
    component: Integrations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CLIENTS,
    name: "Clientes",
    component: Clients,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CLIENTS_BALANCES + "/:client_id",
    name: "Balance de Clientes",
    component: BalancesClient,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CLIENTS + "/delivery-points/:client_id",
    name: "Punto de entrega",
    component: DeliveryPoint,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REMITOS,
    name: "Remitos",
    component: Remitos,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_PRODUCTS,
    name: "Productos",
    component: Products,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_PRODUCT_TYPE,
    name: "Tipos de producto",
    component: ProductType,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_PRICE_LIST,
    name: "Lista de precios",
    component: PriceList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_PRICE_LIST + "/:price_list_id",
    name: "Lista de precios detalle",
    component: PriceListDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_PAYMENT_METHODS,
    name: "Formas de Pago/Cobro",
    component: PaymentMethods,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_CATEGORIES,
    name: "Categorías",
    component: Categories,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_SUBCATEGORIES,
    name: "Categorías",
    component: SubCategories,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SUPPLIERS,
    name: "Proveedores",
    component: Suppliers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SUPPLIERS_BALANCES + "/:supplier_id",
    name: "Balance de Proveedores",
    component: BalancesSupplier,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_MONEY_ACCOUNTS,
    name: "Cuentas Monetarias",
    component: MoneyAccounts,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_SPENTS,
    name: "Gatos",
    component: Spents,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SPENT_PAYMENTS,
    name: "Pagos",
    component: Payments,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CHARGES,
    name: "Cobros",
    component: Charges,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_BANKS_MOVEMENTS,
    name: "Movimientos Bancarios",
    component: BankMovements,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CHECKS,
    name: "Cheques",
    component: Cheques,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_METRICS,
    name: "Metricas",
    component: Metrics,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SETTINGS,
    name: "Configuracion",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SALESMAN,
    name: "Vendedores",
    component: Salesman,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_COSTS_CENTERS,
    name: "Centros de Costos",
    component: CostsCenters,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SUB_COSTS_CENTERS,
    name: "Sub Centros de Costos",
    component: SubCostsCenters,
    meta: {
      requiresAuth: true,
    },
  },
  //REPORTES
  {
    path: "/" + Constant.SECTION_REPORTE_CTA_CTE_CLIENT,
    name: "Cuentas por cobrar",
    component: ReporteCuentasPorCobrar,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORT_IVA_DIGITAL,
    name: "Iva Libro Digital",
    component: IvaLibroDigital,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORTE_CTA_CTE_SUPPLIER,
    name: "Cuentas por pagar",
    component: ReporteCuentasPorPagar,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/client/pending-vouchers/:client_id",
    name: "Comprobantes Pendientes De Cobro",
    component: ComprobantesPendientesDeCobroPorCliente,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/supplier/pending-vouchers/:supplier_id",
    name: "Comprobantes Pendientes De Pago",
    component: ComprobantesPendientesDePagoPorProveedor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORTE_CLIENT_PENDING_CHARGE,
    name: "Cobranzas pendientes",
    component: ComprobantesPendientesDeCobro,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORTE_SUPPLIER_PENDING_SPENT,
    name: "Pagos pendientes",
    component: ComprobantesPendientesDePago,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORTE_SALES_BY_PRODUCT,
    name: "Ventas por producto",
    component: ReporteVenta,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORT_SALESMAN_COMMISSION,
    name: "Comisiones de Vendedores",
    component: ReporteSalesmanCommission,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORT_RETENTIONS,
    name: "Retenciones",
    component: ReportRetentions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_REPORT_PERCEPTIONS,
    name: "Percepciones",
    component: ReportPerceptions,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/" + Constant.SECTION_REPORT_COSTS_CENTERS,
    name: "Centros de costo",
    component: CentrosDeCosto,
    meta: {
      requiresAuth: true,
    },
  },

  //LOGISTIC
  {
    path: "/" + Constant.SECTION_CARRIER,
    name: "Transportistas",
    component: Carrier,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_ZONE,
    name: "Zonas",
    component: Zone,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_RATE,
    name: "Tarifas",
    component: Rate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SHIPPING,
    name: "Viajes",
    component: Shipping,
    meta: {
      requiresAuth: true,
    },
  },

  //FACTURACION
  {
    path: "/" + Constant.SECTION_EMITTERS,
    name: "Emisores",
    component: Emitters,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_RECEIVERS,
    name: "Receptores",
    component: Receivers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_VOUCHERS,
    name: "Comprobantes",
    component: Vouchers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_PURCHASE_ORDER,
    name: "Ordenes de Compras",
    component: PurchaseOrder,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_SALE_ORDER,
    name: "Ordenes de Ventas",
    component: SaleOrder,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_EMPLOYEES,
    name: "Empleados",
    component: Employees,
    meta: {
      requiresAuth: true,
    },
  },

  //STOCK
  {
    path: "/" + Constant.SECTION_WAREHOUSES,
    name: "Depositos",
    component: Warehouses,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_STOCKS,
    name: "Inventario",
    component: Stocks,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_HISTORIAL_STOCKS,
    name: "Historial de Inventario",
    component: HistorialStocks,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CLIENT_TYPE,
    name: "Tipos de cliente",
    component: ClientType,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + Constant.SECTION_CLIENT_ZONE,
    name: "Zonas de cliente",
    component: ClientZone,
    meta: {
      requiresAuth: true,
    },
  },
];

var router = null;
router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.isAuthenticated()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (authService.isAuthenticated() && to.path == "/login") {
      const user_type = localStorage.getItem("user_type");
      console.log("user_type", user_type);
      if (user_type == "S") {
        next({
          path: "/" + Constant.SECTION_USERS,
        });
      } else {
        next({
          path: "/" + Constant.SECTION_METRICS,
        });
      }
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
